import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(resourcesToBackend((language, namespace) => import(`./Locales/${language}/${namespace}.json`)))
  .on('failedLoading', (lng, ns, msg) => console.error(msg))
  .init({
    debug: true,
    fallbackLng: ['fr', 'en'],
    interpolation: {
      escapeValue: false,
    },
    ns: ['common'],
    defaultNS: 'common',
    detection: {
      order: ['path'],
      caches: [],
    },
});

export default i18n;
